export const environment = {
  production: true,
  storage: localStorage,
  // baseUri: 'http://52.40.207.123/abhidemo/',
  // baseUri: 'http://52.40.207.123/abhidemotest/',
   //baseUri: 'http://localhost/beta/api/',
  //  baseUri: 'https://new.pure.bi/api/',

  // baseUri:'https://app.pure.bi/api/',
  // APT_KEY:'690988255366-u0d935begk83mbm02qp8vvp26tbg568j.apps.googleusercontent.com',
  // requestUri : 'https://app.pure.bi',
  // nodeUrl1:'https://5mdojpnyepplmfyh2r2sczhhne0sagcc.lambda-url.ap-south-1.on.aws/',

  // baseUri:'https://dev.pure.bi/api/',
  // APT_KEY:'690988255366-epi4918ft2506v559p4rch6dfmj3o2a9.apps.googleusercontent.com',
  // requestUri : 'https://dev.pure.bi',
  // nodeUrl1:'https://gjhqnqlik6wv736z3wlur6thrq0pniby.lambda-url.ap-south-1.on.aws/',

  // baseUri:'https://csc.pure.bi/api/',
  //requestUri : 'http://localhost:4200',
  // requestUri : 'https://csc.pure.bi',

  // Common
  nodeUrl:'https://njserv.pure.bi/api/',
  APT_KEY:'690988255366-8pljg2hib281h6llvi660s1qpt25pm6b.apps.googleusercontent.com',


//Production 
  baseUri:'https://app.pure.bi/api/',
  requestUri : 'https://app.pure.bi',
  nodeUrl1:'https://oush42hfe6ydcmxiqr73mbzu5e0exasj.lambda-url.ap-south-1.on.aws/',
  
  
  //Development
  // baseUri:'https://test.pure.bi/api/',
  // requestUri : 'https://test.pure.bi',
  // nodeUrl1:'https://5mdojpnyepplmfyh2r2sczhhne0sagcc.lambda-url.ap-south-1.on.aws/',
  
};
